<!-- 校园安全-车辆出入查询 -->
<template>
    <div class="center-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
                <div class="filter-wrap">
                    <expand-filter
                        :closeWidth="1190"
                        :formData="formData"
                        marginBottom="0px"
                        label-width="90px"
                        @clickBtn="clickBtn"
                        @changeBtnFormType="changeBtnFormType"
                    ></expand-filter>
                    <div class="button-line" v-hasPermi="['accessRecord:export']"></div>
                    <el-button v-hasPermi="['accessRecord:export']" @click="clickBtn({ item: { fn: 'export' } })" :loading="exportLoading">导出</el-button>
                </div>
                <table-data
                    v-loading="loadingTable"
                    id="table"
                    ref="table"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor"
                >
                </table-data>
                <Pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    Pagination,
    // 功能组件
    Error,
    Loading,
} from 'common-local';
import TableData from "@/components/scrollWrapper/Sub/TableData"
import {
    mapState
} from 'vuex';
import ExpandFilter from "./Sub/ExpandFilter";
import {downloadFile} from "@/libs/utils";
import onResize from "@/mixins/onResize";

export default {
    name: "CampusSafetyCar",
    mixins: [onResize],
    components: {
        TableData,
        Pagination,
        Error,
        Loading,
        ExpandFilter,
    },
    data() {
        return {
            // 头部筛选
            formData: {
                btnFormType: true,
                data: [
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '姓名',
                        key: 'teacherName',
                        list: []
                    },  {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '出入状态',
                        key: 'type',
                        list: [
                            {label: '出校', value: '1'},
                            {label: '入校', value: '0'},
                        ]
                    },{
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '车牌号',
                        key: 'carNo',
                        list: []
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '手机号',
                        key: 'phone',
                        list: []
                    },
                    {
                        type: "datePick",
                        dateType: "datetimerange",
                        valueFormat: "yyyy-MM-dd HH:mm:ss",
                        format: "yyyy-MM-dd HH:mm:ss",
                        label: "",
                        value: "",
                        customClassName: 'no-clear',
                        key: "time",
                        width: "340px",
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                        defaultTime: ["00:00:00", "23:59:59"],
                        pickerOptions:{
                            onPick: ({ maxDate, minDate }) => {
                                this.listQuery.timeStart = minDate.getTime();
                                if(maxDate){
                                    this.listQuery.timeStart =""
                                }
                            },
                            disabledDate: (time) => {
                                if(this.listQuery.timeStart){
                                    const one = 1000* 3600 * 24 * this.dayNumber; // 一个月
                                    const minTime = this.listQuery.timeStart - one;
                                    const maxTime = this.listQuery.timeStart + one;
                                    return (time.getTime() < minTime || time.getTime() > maxTime);
                                }else{
                                    return false
                                }
                            }
                        },
                        clearable:false,
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: '查询',
                        fn: 'submit',
                        auth:['accessRecord:list']
                    },
                    {
                        type: "enquiry",
                        text: '重置',
                        fn: 'reset'
                    },
                ]
            },

            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "姓名",
                        prop: "teacherName",
                        align: 'center',
                        labelWidth: '120px',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "手机号",
                        prop: "phone",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "科目",
                        prop: "subjectName",
                        type:'popoverText',
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "车牌号",
                        prop: "carNo",
                        type:'popoverText',
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "出入状态",
                        prop: "typeName",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "出/入校时间",
                        prop: "time",
                        align: 'center',
                    },
                ],
                check: false,
                height: '',
                showIndex: true
            },
            // 表格数据
            table_data: [],
            // 表格选中
            tableListSel: [],
            // 分页器
            total: 0,
            listQuery: {
                schoolId: '',
                pageNum: 1,
                pageRow: 20,
                timeStart: '',
                timeEnd: '',
                time:'',
                teacherName: '',
                type: '',
                carNo: '',
                phone:''
            },
            dayNumber:'',
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: false,

            // 表头
            backgroundHeader: '#FAFBFC',
            // 表头字体
            headerColor: '',
            exportLoading: false
        }
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
            templateInfo: state => state.templateInfo,
            schoolId: state => state.schoolId
        })
    },
    created() {
        this.init()
    },
    mounted() {
    },
    methods: {
        init() {
            this.listQuery.schoolId = this.schoolId
            this.setDefaultTime()
            this.getList()
            
        },
        /**
         * @Description:  设置默认事件
         * @Author: hjm
         * @Date: 2024-09-09 16:06:31
         */        
        setDefaultTime() {
            this.formData.data.forEach((item) => {
                if (item.key === "time") {
                    this.listQuery.timeStart = this.$moment()
                        .startOf("month")
                        .format("YYYY-MM-DD 00:00:00");
                    this.listQuery.timeEnd = this.$moment()
                        .endOf("day")
                        .format("YYYY-MM-DD 23:59:59");
                    item.value = [this.listQuery.timeStart, this.listQuery.timeEnd];
                }
            });
            this.dayNumber = this.$moment().daysInMonth();
        },
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            this.loadingTable = true;
            const time = this.listQuery.time
            this.listQuery.time = '';
            this._fet("/school/schoolSzymzhCarEnterAndOutRecord/list4Page", this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    this.total = res.data.data.total;
                    this.table_data = res.data.data.rows;
                    this.loadingTable = false;
                    this.listQuery.time = time;
                    console.log(this.listQuery.time,'hjm')
                })
            })
        },
        // 选中
        handleSelectionChange(data) {
            this.tableListSel = data;
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case 'submit': // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    })
                    if (this.listQuery.time && this.listQuery.time.length > 0) {
                        this.listQuery.timeStart = this.listQuery.time[0]
                        this.listQuery.timeEnd = this.listQuery.time[1]
                    } else {
                        this.setDefaultTime()
                    }
                    this.getList(1);
                    break;
                case 'reset': // 重置
                    this.formData.data.forEach((item) => {
                        item.value = '';
                        this.listQuery[item.key] = ''
                    })
                    this.listQuery.organId = ''
                    this.setDefaultTime()
                    this.getList(1);
                    break;
                case 'export':
                    this.handleExport()
                    break;
            }
        },
        handleExport() {
            let obj = {
                schoolId: this.schoolId,
                exportType: '2',
                timeStart:'',
                timeEnd:'',
                teacherName:'',
                type:'',
                carNo:'',
                phone:''
            }
           
            let filter = this.formData.data.filter(i => i.value !== '')
            // 未选中筛选条件，导出全部
            if (filter.length === 0) {
                obj.identification = 3
            } else {
                // 选中筛选
                filter.forEach(item => {
                    if (item.key === 'time') {
                        obj.timeStart = item.value[0]
                        obj.timeEnd = item.value[1]
                    } else {
                        obj[item.key] = item.value;
                    }
                })
            }
            this.exportLoading = true;
            downloadFile({url: '/school/schoolSzymzhCarEnterAndOutRecord/export', form: obj}, () => {
                this.$message.success('导出成功')
                this.exportLoading = false;
            }, () => {

            })
        },
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
        initTabColor() {
            this.template_info = this.templateInfo;
            this.template_info.forEach((p, i) => {
                if (p.sel) {
                    this.headerColor = p.textColor;
                }
            })
        },
    },
    watch: {
        'templateInfo': {
            handler() {
                this.initTabColor();
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>

.center-main {
    padding: 0;
    padding-right: 10px;
}
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}
.content {
    border-bottom: 1px solid #f2f6fe;
    display: flex;
    justify-content: space-between;
    padding: 15px;
}
::v-deep .el-range-editor.el-input__inner{
    padding: 3px;
}
</style>
